/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */

export const GET_BLOG_POST_DETAILS = 'GET_BLOG_POST_DETAILS';
export const GET_BLOG_POST_LISTING = 'GET_BLOG_POST_LISTING';

/** @namespace Pwa/Store/BlogPost/Action/updateBlogPostDetails */
export const updateBlogPostDetails = (postDetails) => ({
    type: GET_BLOG_POST_DETAILS,
    postDetails
});

/** @namespace Pwa/Store/BlogPost/Action/updateBlogPostListing */
export const updateBlogPostListing = (postListing) => ({
    type: GET_BLOG_POST_LISTING,
    postListing
});
